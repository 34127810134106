import React from "react";
import { graphql } from 'gatsby'
import loadable from "@loadable/component";
import Layout from "../components/layout";
import { getCategory } from "../utils/news";
import { collectBreadcrum } from "../utils";

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const StaticDetailIntro = loadable(() => import("../components/StaticDetailIntro/StaticDetailIntro"));
const StaticDetailDesc = loadable(() => import("../components/StaticDetailDesc/StaticDetailDesc"));
const FeaturedSlider = loadable(() => import("../components/FeaturedSlider/FeaturedSlider"));

const StaticDetail = (props) => {
    const pageData = props.data.wpPost
    const otherItems = props.data.allWpPost.nodes

    const author = pageData.author.node.name
    const category = getCategory(pageData.categories);

    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule data={collectBreadcrum(props?.pageContext?.data)}/>

            <StaticDetailIntro
                pageData={pageData}
                category={category}
                author={author}
            />

            <StaticDetailDesc
                pageData={pageData}
                category={category}
                author={author}
            />

            <FeaturedSlider 
                tag="static-detail"
                items={otherItems}
            />
        </Layout>
    )
}


export const query = graphql`
  query ($page_id: String) {
    wpPost(id: {eq: $page_id}) {
      id
      author {
        node {
          id
          name
        }
      }
      categories {
        nodes {
          name
        }
      }
      content
      date(formatString: "MMMM, Do YYYY")
      title
    }

    allWpPost(
        filter: {categories: {nodes: {elemMatch: {slug: {eq: "news"}}}}, status: {eq: "publish"}, id: {ne: $page_id}}
        sort: {order: DESC, fields: date}
        limit: 6
    ) {
        nodes {
          uri
          title
          status
          slug
          categories {
            nodes {
              name
            }
          }
          content
          featuredImage {
              node {
                id
                sourceUrl
              }
          }
        }
      }
  }  
`

export default StaticDetail