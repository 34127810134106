import { get } from "lodash";
export const getCategory = (categories, type='string') => {
    let category = []
    categories.nodes.map((c) => {
        if (!category.includes(c.name))
            category.push(c.name)
    })

    if (type == 'string')
        return category.join(', ')
    return category
}

export const getNewsImage = () => {
    return;
}

export const getItemLink = (node) => {
    let slug = '';
    const categories = node.categories.nodes || [];
    categories.map((category) => {
        if (category.parentId === null) {
            slug = `/${node.slug}`;
          if (get(category, 'slug', false))
            slug = `/${category.slug}/${node.slug}/`;
        }
    })
    return slug;
}